import axios from "axios";

declare global {
  interface Window {
    config: {
      ssoApiUrl: any;
      tseApiUrl: any;
      clientID: any;
      notifyApiUrl: any;
      signalRUrl:any;
      sessionTimeOut : any;
      helpLink : any;
      sessionTimeOutAlert : number;
    };
  }
}

console.log(window);

export const SSOApi = axios.create({
  // baseURL: appsettings.URL_TSE_API,
  baseURL: window.config.ssoApiUrl,
  headers: {
    "Content-type": "application/json",
  },
});

export const TSEApi = axios.create({
  // baseURL: appsettings.URL_TSE_API,
  baseURL: window.config.tseApiUrl,
  headers: {
    "Content-type": "application/json",
  },
});

export const NotifyApi = axios.create({
  // baseURL: appsettings.URL_TSE_API,
  baseURL: window.config.notifyApiUrl,
  headers: {
    "Content-type": "application/json",
  },
});
