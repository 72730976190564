import React from 'react'
import MyProfileForm from './MyProfile.component';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const MyProfile = () => {

    const history = useHistory();
    const user = useSelector((slice: any) => slice.user.user);

    const handleChangePwdSuccess = () => {
        history.goBack()
    }

    const handleChangePwdCancel = () => {
        history.goBack()
    }

    return (
        <div>
            <MyProfileForm
                handleClose={handleChangePwdCancel}
            />
        </div>
    )
}

export default MyProfile