import { styled } from '@mui/material/styles'
import { InputAdornment, Stack, TextField } from "@mui/material"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '9.5px 0'
        },
        '& label.Mui-focused': {
            color: '#A0AAB4',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#B2BAC2',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#D9D9D9',
                borderRadius: '6px'
            },
            '&:hover fieldset': {
                borderColor: '#B2BAC2',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
    },
});

type GInputProps = {
    variant: 'regular' | 'small' | 'large',
    name?: string;    
    label: string;
    color? : boolean;
}

const GLabel = ({ name, variant, label,color }: GInputProps) => {
    
    const regularStyle: React.CSSProperties = {
        fontFamily: 'DiscoverSans-Medium',
        fontSize: '14px',
        fontWeight: '600',
        letterSpacing: '.5px',
    }

    const largeStyle: React.CSSProperties = {
        fontFamily: 'DiscoverSans-Semibold',
        fontSize: '14px',
        fontWeight: '600',
        letterSpacing: '.5px',
    }

    const smallStyle: React.CSSProperties = {
        fontFamily: 'DiscoverSans-Light',
        fontSize: '14px',
        fontWeight: '600',
        letterSpacing: '.5px',
    }

    const colorStyle: React.CSSProperties = {
        color : "#2477ab",
        marginLeft: '10px'
    }




    return (
        <>
            {
                color ? <label style={{...regularStyle,...colorStyle}}>{label} </label> :
                <label style={regularStyle}>{label} </label>
            }
        </>
    )
}

export default GLabel