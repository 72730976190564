import { SSOApi, TSEApi } from "../http-common";

export const SetSSOToken = (token: string) => {
  console.log("SetSSOToken is called")
  SSOApi.defaults.headers.common = { Authorization: `bearer ${token}` };
};
/**
 * User Account Controller
 * It was written based on the API document
 */
export const API_SSO = {
  ValidationPassword: (emailID: string, password: string) => {
    return SSOApi.post("api/sso/validatepasswordlogin", null, {
      params: {
        emailID: emailID,
        password: password,
        clientID: window.config.clientID,
      },
    });
  },
  ValidationOtp: (emailId: string, otp: string) => {
    return SSOApi.post("api/sso/validateotplogin", null, {
      params: {
        emailId: emailId,
        otp: otp,
        clientID: window.config.clientID,
      },
    });
  },
  GenerateOtp: (emailId: string) => {
    return SSOApi.post("api/sso/generateotplogin", null, {
      params: {
        emailId: emailId,
      },
    });
  },
  ChangePassword: (
    emailId: string,
    oldPassword: string,
    newPassword: string
  ) => {
    return SSOApi.post("api/user/change-password", null, {
      params: {
        emailId: emailId,
        oldPassword: oldPassword,
        newPassword: newPassword,
        clientID: window.config.clientID,
      },
    });
  },
  ResetPassword: (emailId: string) => {
    return SSOApi.post("api/user/reset-password", null, {
      params: {
        emailId: emailId,
        clientID: window.config.clientID,
      },
    });
  },
  GetApplications: () => {
    return SSOApi.get("api/app/getapplications");
  },
  GetUser: (emailId: string) => {

    console.log(SSOApi.defaults.headers.common)
    return SSOApi.get("api/user/get-user", {
      params: {
        emailId: emailId,
      },
    });
  },
  SSOLogout: (emailId: string, token: string) => {
    SSOApi.defaults.headers.common = { Authorization: `${token}` };
    return SSOApi.get("api/sso/ssoLogout", {
      params: {
        userName: emailId,
      },
    });
  },
};

export const API_TSE = {
  DownladOrderForm: (filePath: string) => {
    return TSEApi.post("/api/ProjectSubmit/downloadOrderForm", null, {
      params: {
        filePath: filePath,
      },
    }).then(function (response) {
      console.log(response);

      var fileName = filePath.split("/").pop()?.split("\\").pop();
      console.log(fileName);
      //response.
      let blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      //link.download = "tse_report.zip";
      try {
        link.download = fileName ? fileName : "orderForm.pdf";
      } catch (err) {
        console.error(err);
      }

      link.click();
    });
  },
};
