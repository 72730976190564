import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import CachedIcon from "@mui/icons-material/Cached";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const IconStyle: React.CSSProperties = {
  fontSize: "inherit",
};

export const PHIcons = {
  AccountCircleIconFilled: <AccountCircleIcon sx={IconStyle} />,
  KeyboardArrowDownIcon: <KeyboardArrowDownIcon sx={IconStyle} />,
  KeyboardArrowUpIcon: <KeyboardArrowUpIcon sx={IconStyle} />,
  NotificationsIcon: <NotificationsIcon sx={IconStyle} />,
  LogoutIcon: <LogoutIcon sx={IconStyle} />,
  CachedIcon: <CachedIcon sx={IconStyle} />,
  ArrowDownwardIcon: <ArrowDownwardIcon sx={IconStyle} />,
  DescriptionOutlinedIcon: <DescriptionOutlinedIcon sx={IconStyle} />,
  EyeOn: <RemoveRedEyeIcon sx={IconStyle} />,
  EyeOff: <VisibilityOffIcon sx={IconStyle} />,
};
