
class Constants {

    static readonly REACT_SSO_CONTROLLER_SERVICE_BASE_URL: string = "https://payhuddle-staging.com/SSOAPI/gateway/sso/";
    static readonly REACT_APP_CONTROLLER_SERVICE_BASE_URL: string = "https://payhuddle-staging.com/SSOAPI/gateway/app/";
    static readonly SSO_BASE_URL: string = "https://payhuddle-staging.com/SSOAPI";
}
export default Constants;


export class AuthResults {
    public static readonly INVALID: string = "INVALID";
    public static readonly DONE: string = "DONE";
    public static readonly OTP_AUTH_NEED: string = "OTP_AUTH_NEED";
    public static readonly SSO_AUTH_NEED: string = "SSO_AUTH_NEED";
}

