import React from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "./components/Login";
import ApplicationCard from "./components/ApplicationList";
import PrivateRoute from "./components/Router";
import AdminPortal from "./components/AdminPortal";
import { Provider } from 'react-redux';
import Test from "./components/DropDown/Test.component";
import { CRoutePaths } from "./constants/CApp";
import '../src/fonts/discoverSans/DiscoverSans_family.css';
import ChangePassword from "./components/ChangePassword";
import MyProfile from "./components/MyProfile";
import { store } from "./redux-store/store";
import { UseSessionManager } from "./browser/session/SessionManager";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router basename="/SSO">
        <Switch>          
          <Route path={CRoutePaths.Login} component={Login} />
          <PrivateRoute path={CRoutePaths.Applications} component={ApplicationCard} />
          <PrivateRoute path={CRoutePaths.ChangePassword} component={ChangePassword} />
          <PrivateRoute path={CRoutePaths.MyProfile} component={MyProfile} />
          <PrivateRoute path="/adminportal" component={MyProfile} />
          <PrivateRoute path="/test" component={Test} />
          <Redirect exact from="/" to={CRoutePaths.Login} />
        </Switch>
      </Router>
    </Provider>

  );
};

export default App;
