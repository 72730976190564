import React, { useEffect, useState } from "react";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Spin,
  Typography,
  message,
  notification,
} from "antd";
import "./index.css";
import discoverlogo from "../../assets/g12.svg";
import { RouteComponentProps, useHistory } from "react-router-dom";
import notificaticationBg from "../../assets/LoginBackground.png";
import DiscoverLogo from "../../assets/discover-logo.svg";
import Constants from "../Constants";
import ApiServices from "../Services";
import { useDispatch } from "react-redux";
import CookieManager from "../Cookies";
import LoginForm from "./LoginForm.component";
import Header from "../Header/Header.component";
import ChangePasswordForm from "../ChangePassword/ChangePassword.component";
import { CLocalStorage } from "../../constants/CLocalStorage";
import { CApp } from "../../constants/CApp";
import Footer from "../Footer";

const ssoUrl = Constants.REACT_SSO_CONTROLLER_SERVICE_BASE_URL;
const appUrl = Constants.REACT_APP_CONTROLLER_SERVICE_BASE_URL;

const Login: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const [isUsername, setIsUsername] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [generatedToken, setGeneratedToken] = useState();
  const [apiOtpResult, setApiOtpResult] = useState(false);
  const [isCookiePresent, setIsCookiePresent] = useState("");
  const [loading, setLoading] = useState(false);
  const formRef = React.createRef<FormInstance>();
  const [emailID, setEmailID] = useState("");
  const [canShowChangePWD, setShowChangePwd] = useState(false);

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  useEffect(() => {
    var manager = new CookieManager();
    const cookiesPresent = manager.getCookie(false);
    setIsCookiePresent(cookiesPresent);
    if (cookiesPresent) {
      // window.history.replaceState(null, '', window.location.href);

      // window.addEventListener('popstate', () => {
      //   window.history.forward();
      // });

      console.log(cookiesPresent);

      var email = sessionStorage.getItem(CLocalStorage.Keys.Email);
      history.replace(`/applications?email=${email}`);
    } else {
      console.log("Error ");
    }

    // return () => {
    //   window.removeEventListener('popstate', () => {
    //     window.history.forward();
    //   });
    // };
    return;
  }, [history]);

  const handleLoginChangePwdEvent = (emailID: string) => {
    setEmailID(emailID);
    setShowChangePwd(true);
  };

  const handleChangePwdSuccess = () => {
    // sessionStorage.setItem("email", emailID);
    // localStorage.setItem("email", emailID);

    // console.log(sessionStorage.getItem("email"));
    // history.push(`/applications?email=${emailID}`);
    setShowChangePwd(false);
  };

  const handleChangePwd = () => { };

  return (
    <>
      {loading ? (
        <>
          <div className="loader-container">
            <Spin size="large" />
          </div>
        </>
      ) : (
        <>
          <Row className="row_1">
            <Col span={24}>              
              <Header
                isLogin={false}
                user={null}
                onClickChangePwd={handleChangePwd}
              />
            </Col>
          </Row>
          <Row className="login_page">
            <Col className="login_form">
              <Col className="form_contains">
                {canShowChangePWD ? (
                  <ChangePasswordForm
                    isSingleColumn={true}
                    emailID={emailID}
                    handleSucess={handleChangePwdSuccess}
                    handleCancel={() => { }}
                    isNeedCancel={false}
                  />
                ) : (
                  <LoginForm onEnableChangePwd={handleLoginChangePwdEvent} />
                )}
              </Col>
            </Col>
            <Col className="login_page_poster">
              <img src={notificaticationBg} alt="Notification_Bg" />
              <p>
                Sign in to explore the amazing world of Discover testing
                applications
              </p>
            </Col>
          </Row>
          <Footer />
        </>
      )}
    </>
  );
};
export default Login;
