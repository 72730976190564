import { Button, Card, Col, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import "../ApplicationList/index.css";
import cardLogo from "../../assets/Frame 2608358.svg";
import globalLogo from "../../assets/Group 44236.svg";
import userLogo from "../../assets/User Placeholder.svg";
import DiscoverLogo from "../../assets/discover-logo.svg";
import Constants from "../Constants";
import ApiServices from "../Services";
import { flushSync } from "react-dom";
import CookieManager from "../Cookies";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import Notification from "../Notification/Notification.component";
import { Grid } from "@mui/material";
import Header from "../Header/Header.component";
import { API_SSO, SetSSOToken } from "../../api/ssoapi/SSOapi";
import ChangePasswordForm from "../ChangePassword/ChangePassword.component";
import { openUrl } from "../../Utils/LinkUtils.hooks";
import { CLocalStorage } from "../../constants/CLocalStorage";
import { CApp, CRoutePaths } from "../../constants/CApp";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux-store/store";
import { UpdateApplications } from "../../slices/UserSlice";

const appUrl = Constants.REACT_APP_CONTROLLER_SERVICE_BASE_URL;
const ssoUrl = Constants.REACT_SSO_CONTROLLER_SERVICE_BASE_URL;

const ApplicationCard: React.FC<RouteComponentProps> = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  //const searchParams = new URLSearchParams(window.location.search);
  //const paramEmailId = searchParams.get("email");
  //console.log("paramEmailId",paramEmailId)

  //const [data, setData] = useState<any[]>([]);
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [isCookiePresent, setIsCookiePresent] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isApplicationId, setIsApplicationId] = useState<any[]>([]);
  const [token, setToken] = useState<string>("");

  const user = useSelector((slice: RootState) => slice.user.user);
  const applications = useSelector((slice: RootState) => slice.user.applications);

  useEffect(() => {
    if (true || isValidSession()) {
      console.log("GetUser")
      // getUser();
      getAllApplication();

      var ssoToken = sessionStorage.getItem(CLocalStorage.Keys.Token);
      if (ssoToken) {
        setToken(ssoToken);
      }
    }
    else {
      console.log("handling logout");
      handleLogout();
    }

  }, []);

  useEffect(() => {
    var manager = new CookieManager();
    const cookiesPresent = manager.getCookie(false);
    setIsCookiePresent(cookiesPresent);
    if (cookiesPresent) {
      var email = sessionStorage.getItem(CLocalStorage.Keys.Email);
      history.push(`/applications?email=${email}`);
    }
    return;
  }, [history]);

  useEffect(() => {
    if (user) {
      var appIDs: string[] = [];
      if (user?.accessApplications?.length > 0) {
        user.accessApplications.map((accApp: any) => {
          if (accApp?.state == "ACTIVE") {
            appIDs.push(accApp.applicationID);
          }
        });
      }

      setIsApplicationId(appIDs);
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      var manager = new CookieManager();
      var sessionMangEmail: any = sessionStorage.getItem(CLocalStorage.Keys.Email);

      sessionMangEmail = encodeURIComponent(sessionMangEmail);
      // ToDo: Need to change this implementation
      console.log("---------------------------------------");
      console.log(sessionMangEmail);
      console.log(manager.getCookie(false));
      console.log(sessionMangEmail);


      const connect = new HubConnectionBuilder()
        .withUrl(
          window.config.signalRUrl + `hub/notification?token=${token}&username=${sessionMangEmail}`
        )
        .withAutomaticReconnect()
        .build();

      console.log(connect);

      setConnection(connect);
    }
  }, [token]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("PostMessage", (message) => {
            console.log(message);
            if (message == "log out") {
              handleLogout();
            }
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  const isValidSession = () => {
    var emailID = sessionStorage.getItem(CLocalStorage.Keys.Email)
    var token = sessionStorage.getItem(CLocalStorage.Keys.Token)

    if ((emailID && emailID?.length > 0) &&
      (token && token?.length > 0)) {

      SetSSOToken(token);
      return true;
    }
    else {
      return false;
    }
  }

  const getAllApplication = async () => {
    try {
      const apps = await API_SSO.GetApplications();

      // const apiServices = new ApiServices(apiUrl, apiParam);
      // let result = await apiServices.get();
      if (apps.data !== undefined) {
        //setData(apps.data);
        dispatch(UpdateApplications({ applications: apps.data }))
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleLogout = async () => {
    const email = sessionStorage.getItem(CLocalStorage.Keys.Email);

    if (email) {
      try {
        setLoading(true);

        var res = await API_SSO.SSOLogout(email ? email : "", token ? token : "");

        if (res.data) {
          history.replace(CRoutePaths.Login);
          var manager = new CookieManager();
          const deletePresentCookie = manager.deleteCookie("PH_SSO");
        } else {
          console.error("API response indicates failure.");
        }
      } catch (error) {
        console.error("Form validation error: ", error);
      }
    }
    else {
      history.replace(CRoutePaths.Login);
      var manager = new CookieManager();
      const deletePresentCookie = manager.deleteCookie("PH_SSO");
    }
  };

  const onAdminPortal = async () => {
    history.push("/adminPortal");
  };

  const cursorStyle = (itemId: any) => {
    let cursor = "not-allowed";
    let opacity = 0.5;
    isApplicationId.map((items) => {
      if (items.includes(itemId)) {
        cursor = "pointer";
        opacity = 1;
      }
    });

    return { cursor, opacity };
  };
  const handleOpenApplication = (isClickable: boolean, item: any) => {
    if (isClickable && item && item.setupUrl.loginUrls?.length) {
      var appUrl = item.setupUrl.loginUrls[0] + `?token=${token}`
      openUrl({
        isNewTab: true,
        isHistoryNeed: false,
        url: appUrl
      });
    }
  }

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {/* <Row className="row_1">
            <Col className="col_1" span={24}>
              <div className="navbar-wrapper">
                <img src={`/SSO/${DiscoverLogo}`} className="logo" alt="" />
                <span>Discover Innovation Gateway</span>
              </div>
              <Button
                type="primary"
                size="small"
                className="btn_color"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Col>
          </Row> */}
          {/* <div style={{ display: "flex" }}> */}

          {/* <div className="Apps_lists"> */}

          {/* <Col className="admin_card">
                <Card
                  style={{
                    cursor: isAdmin ? "pointer" : "not-allowed",
                  }}
                  onClick={isAdmin ? onAdminPortal : undefined}
                >
                  <div>
                    <img
                      className="admin_img"
                      src={`/SSO/${userLogo}`}
                      alt="adminLogo"
                    />
                    <span className="span_name"> Admin Portal</span>
                    <span className="span_description"> Under development</span>
                  </div>
                </Card>
              </Col> */}

          {/* <div className="notification_col">

              </div> */}
          {/* </div> */}
          {/* </div> */}
          <Grid container className="apps-container">
            <Grid item md={6} className="list-container">
              <div className="lists">
                {applications.length === 0 ? (
                  <p></p>
                ) : (
                  applications.map((item: any) => {
                    const isClickable = isApplicationId.some((items) =>
                      items.includes(item.id)
                    );
                    const isView =
                      item?.displayState == "VIEW" ? true : false;
                    console.log(isClickable);
                    return (
                      <Col key={item.id} className="app_card">
                        {isView ? (
                          <>
                            {isClickable ? (
                              <span
                                style={{ textDecoration: "none" }}
                                key={item.id}
                                onClick={() => {
                                  handleOpenApplication(isClickable, item)
                                }}
                              >
                                <Card
                                  className="app-list-item-card"
                                  style={{
                                    ...cursorStyle(item.id),
                                  }}
                                >
                                  <div className="app_list">
                                    <img
                                      className={item.name}
                                      src={`/SSO/${item.image}`}
                                      alt={item.name}
                                    />
                                  </div>
                                  <hr></hr>
                                  <div>
                                    <div className="span_name">
                                      {item.name}
                                    </div>
                                    {/* <div className="span_description">
                                      {item.description}
                                    </div> */}
                                  </div>
                                </Card>
                              </span>
                            ) : (
                              <Card
                                style={{
                                  ...cursorStyle(item.id),
                                }}
                              >
                                <div className="app_list">
                                  <img
                                    className={item.name}
                                    src={`/SSO/${item.image}`}
                                    alt={item.name}
                                  />
                                </div>
                                <hr></hr>
                                <div>
                                  <div className="span_name">{item.name}</div>
                                  {/* <div className="span_description">
                                    {item.description}
                                  </div> */}
                                </div>
                              </Card>
                            )}
                          </>
                        ) : (
                          <>
                            <div></div>
                          </>
                        )}
                      </Col>
                    );
                  })
                )}
              </div>
            </Grid>
            <Grid sx={{ height: 'calc(100% - 40px)' }} item md={6}>
              <Notification />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
export default ApplicationCard;
