interface RememberMe {
    userName:string,
    password: string,
    isEnabled:boolean;
};


/**
 * if checkbox checked the user details will store in localstorage
**/ 
export const performRememberMe = (storageKey : string,loginData:RememberMe) => {
    if (loginData.userName?.length > 0 && loginData.password?.length > 0) {
        const formData = { UserName: loginData.userName, Password: loginData.password ,isChecked :loginData.isEnabled};
        console.log(formData)
        console.log(loginData)
        if (loginData.isEnabled) {
            localStorage.setItem(storageKey, JSON.stringify(formData))
            console.log("performRememberMe -> data stored in localstorage")
        } else {
            console.log("performRememberMe -> remember me checkbox not enabled")
            localStorage.removeItem(storageKey)
        }
    } else {
        console.log("performRememberMe -> Enter a valid details")
    }
}

/**
 * it fetch the data if already store in localstorage
**/ 

export const getRememberMeDetails = (storageKey : string,) : RememberMe => {
    let storedValue = localStorage.getItem(storageKey);
    if (storedValue !== null) {
        console.log("getRememberMeDetails -> data found  " + storedValue)
        const userObject : RememberMe= JSON.parse(storedValue)
        return userObject
        
    }
    else {
        console.log("getRememberMeDetails -> No data found")
        return {
            isEnabled : false,
            password : "",
            userName : ""
        }
    }
}
