import React, { useState } from 'react'
import { Stack, Menu, MenuItem, Divider } from "@mui/material"
import { PHIcons } from "../Icons/PHIcons";

export interface IMenuList {
    name: any,
    route: string,
    onClick: () => void;
}

type IconWithNameDropDownProps = {
    locationPath: string,
    icon: any,
    userName: string,
    menuList: IMenuList[];
    isMenuOpen: null | HTMLElement
    handleClose: () => void
    handleClick: (event: React.MouseEvent<HTMLElement>) => void
}

const IconWithNameDropDown = ({ icon, userName, menuList, handleClose, isMenuOpen, handleClick, locationPath }: IconWithNameDropDownProps) => {
    const open = Boolean(isMenuOpen);

    const spanStyle: React.CSSProperties = {
        color: '#ec6c2a'
    }
    const labelStyle: React.CSSProperties = {
        fontFamily: 'Noto Sans',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '19px'
    }

    const MenuItemStyle: React.CSSProperties = {
        fontFamily: 'DiscoverSans-Medium',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '19px',
        color: 'black',
        margin: '0px',
        padding: '2px 10px 2px 10px',
        letterSpacing: '.5px'
    }

    const MenuItemStyleActive: React.CSSProperties = {
        fontFamily: 'DiscoverSans-Bold',
        fontSize: '14px',
        // fontWeight: '600',
        lineHeight: '19px',
        color: '#EC6C2A',
        margin: '0px',
        padding: '2px 10px 2px 10px',
        letterSpacing: '.5px'
    }

    return (
        <>
            <Stack direction={'row'} columnGap={1} onClick={handleClick} >
                {icon}
                {/* <Stack direction={'column'} justifyContent={'center'} >

                    <Stack direction={'row'} columnGap={1} alignItems={'center'}>

                        <label style={labelStyle}>{userName}</label>
                        <span onClick={handleClick} style={spanStyle}>{PHIcons.KeyboardArrowDownIcon}</span>
                    </Stack>
                </Stack> */}
            </Stack>
            <Menu
                id="basic-menu"
                anchorEl={isMenuOpen}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}

            >
                <Stack sx={{ margin: '0 6px 0 6px' }}
                    direction={'column'} justifyContent={'flex-start'} divider={<Divider sx={{ color: '#ec6c2a' }} orientation='horizontal' flexItem />} >

                    {menuList.map((menuItem, index) => (
                        <MenuItem sx={locationPath === menuItem.route ? MenuItemStyleActive : MenuItemStyle} key={index} onClick={menuItem.onClick}>{menuItem.name}</MenuItem>
                    ))}
                </Stack>

            </Menu>
        </>
    )
}

export default IconWithNameDropDown