interface Url {
    url: string;
    isNewTab: boolean;
    isHistoryNeed: boolean;
}

// it handles "Opening excel and document URL File in new tab or same tab" 
export const openUrlFiles = (url: Url) => {
    const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(url.url)}&embedded=true`;
    if (url.isNewTab === true) {
        console.log("Opening a file in New Tab from a URL")
        window.open(googleDocsViewerUrl, "_blank");
    } else {
        console.log("Opening a file in Same Tab from a URL")
        window.open(googleDocsViewerUrl, "_self");
    }
}

// handles "downloading excel and document url files"
export const downloadUrlFiles = (url: Url) => {
    const link = document.createElement('a')
    link.href = url.url
    link.download = 'download'
    link.click()
}


// it handles the url to open in new tab or same tab with handle of route history
export const openUrl = (url: Url) => {
    if (url.isNewTab === true) {
        window.open(url.url, '_blank')
    } else if (url.isNewTab === false && url.isHistoryNeed === false) {
        window.location.replace(url.url)
    } else {
        window.open(url.url, '_self')
    }
}


// it handles the pdf url to open in new tab or same tab
export const openPdfUrlFiles = (url: Url) => {
    if (url.isNewTab === true) {
        window.open(url.url, '_blank')
    } else if (url.isNewTab === false && url.isHistoryNeed === false) {
        window.location.replace(url.url)
    } else {
        window.open(url.url, '_self')
    }
}





