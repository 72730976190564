import React, { useEffect, useState } from "react";
import NotificationDivStyle from "./NotificationDivStyle.component";
import { IFileData } from "./NotificationModal.component";
import "./Notification.styles.css";
import AutoScroll from "../AutoScroll/AutoScroll.component";
import { useFetch } from "../../hooks/useFetch";
import { usePost } from "../../hooks/usePost";
import { API_Notify, IGetNotificationReq } from "../../api/notifyapi/Notifyapi";
import UpArrow from '../../assets/up-arrow-svg.svg'
import DownArrow from '../../assets/down-arrow-svg.svg'
import { useSelector } from "react-redux";
import { RootState } from "../../redux-store/store";

interface INotificationData {
    userId: string;
    roles: string[];
    partnerId: string;
}

type NotificationProps = {
    notificationData?: INotificationData;
};

console.log("Notificatoin");
const Notification = ({ notificationData }: NotificationProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<any[]>([]);
    const getIsModalOpen = (isModal: boolean) => {
        setIsModalOpen(isModal);
    };
    const [isPauseNotification, setIsPauseNotification] = useState<boolean>(false);
    const [moveNotificationToTop, setMoveNotificationToTop] = useState<boolean>(false);
    const [moveNotificationToBottom, setMoveNotificationToBottom] = useState<boolean>(false);
    const user = useSelector((slice : RootState)=> slice.user.user);
    
    const onMouseOverPauseNotification = () => {
        setIsPauseNotification(true);
    }

    const onMouseOutStartNotification = () => {
        setIsPauseNotification(false);
    }

    const moveNotifToTopHandler = () => {
        setMoveNotificationToTop(true)
        setInterval(() => {
            setMoveNotificationToTop(false);
        }, 500)
    }
    const moveNotifToBottomHandler = () => {
        setMoveNotificationToBottom(true)
        setInterval(() => {
            setMoveNotificationToBottom(false);
        }, 500)

    }

    const file: IFileData = {
        fileName: "202.pdf_20",
        fileSize: "1.23 KB",
        filePath: "",
    };

    useEffect(() => {
        if(user){
            getNotifications();
        }        
    }, [user]);

    const getNotifications = async () => {
        const notificationBody: IGetNotificationReq = {
            userID: user?.email ? user.email : "",
            roles: user?.accessApplications ? user.accessApplications.map(x=>x.applicationID) : [],
            partnerID: "",
        };
        try {
            var res = await API_Notify.GetNotificationByUserID(notificationBody);
            if (res?.data.data) {
                setNotifications(res.data.data);
            }
        } catch (err) { }
    };



    const getFileName = (file: any): string => {
        if (file != null && file != undefined && file?.length > 0) {
            const fileName: string = file.split("\\").pop()?.split("/").pop() || "";
            return fileName;
        }
        return "";
    };

    const getFiles = (files: any): IFileData[] => {
        var outFiles: IFileData[] = [];

        if (files?.map) {
            files.map((file: any) => {
                outFiles.push({
                    fileName: getFileName(file),
                    fileSize: "",
                    filePath: file,
                });
            });
        }
        return outFiles;
    };

    useEffect(() => {
        console.log('moveNotificationToTop', moveNotificationToTop);
        console.log('moveNotificationToBottom', moveNotificationToBottom);
    }, [moveNotificationToTop, moveNotificationToBottom])

    return (
        <>
            <div className="notification-container">
                <div className="notification-label">

                    <label>Notification</label>
                </div>
                <div className="notification-list">
                    <AutoScroll
                        isModalOpen={isModalOpen}
                        isPause={isPauseNotification}
                        isTop={moveNotificationToTop}
                        isBottom={moveNotificationToBottom}
                        styles={{
                            // padding: "5px 35px 5px 35px",
                            // height: "684px",
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "16px",
                        }}
                    >
                        {notifications?.map((notification: any, index: any) => (
                            <NotificationDivStyle
                                getModalIsOpen={getIsModalOpen}
                                key={index}
                                header={notification.title}
                                description={notification.description}
                                files={getFiles(notification?.attachments)}
                            />
                        ))}
                    </AutoScroll>
                </div>

                <div className="scroll-icons" onMouseOver={onMouseOverPauseNotification} onMouseOut={onMouseOutStartNotification} >

                    <img onClick={moveNotifToTopHandler} src={UpArrow} alt="up arrow" />


                    <span className="scroll-btn"></span>
                    <span className="scroll-btn"></span>
                    <span className="scroll-btn"></span>

                    <img onClick={moveNotifToBottomHandler} src={DownArrow} alt="Down arrow" />

                </div>
            </div>

        </>
    );
};

export default Notification;
