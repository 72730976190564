import { styled } from '@mui/material/styles'
import { InputAdornment, Stack, TextField } from "@mui/material"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '9.5px 0'
        },
        '& label.Mui-focused': {
            color: '#A0AAB4',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#B2BAC2',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#D9D9D9',
                borderRadius: '6px'
            },
            '&:hover fieldset': {
                borderColor: '#B2BAC2',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
    },
});

type GInputProps = {
    variant?: 'filled' | 'outlined' | 'standard',
    name?: string;
    value?: any;
    onChange?: (e: any) => void;
    type?: 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week';
    multiline?: boolean;
    error?: boolean;
    helperText?: string;
    icons?: {
        start?: any,
        end?: any
    },
    label?: string,
    onBlur?: () => void;

}

const GInput = ({ name, value, onChange, type, icons, error, helperText, multiline, variant, label, onBlur }: GInputProps) => {

    const classes = useStyles();
    const labelStyle: React.CSSProperties = {
        fontFamily: 'DiscoverSans-Medium',
        fontSize: '14px',
        fontWeight: '600',
        letterSpacing: '.5px',
    }



    return (
        <>
            <Stack direction={'column'} justifyContent={'flex-start'} >
                <label style={labelStyle}>{label} </label>
                <TextField

                    className={classes.root}
                    type={type}
                    multiline={multiline}
                    helperText={helperText}
                    error={error}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                {icons?.start}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position='end'>
                                {icons?.end}
                            </InputAdornment>
                        )
                    }}
                    variant={variant}

                />
            </Stack>
        </>
    )
}

export default GInput