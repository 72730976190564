import React, { useEffect, useRef } from 'react';
import './AutoScroll.styles.css'


type AutoScrollProps = {
    children: any;
    styles?: React.CSSProperties;
    getAutoScroll?: (startAutoScroll: any) => void;
    isModalOpen?: boolean;
    isPause: boolean;
    isTop: boolean;
    isBottom: boolean;
}


const AutoScroll = ({ children, styles, getAutoScroll, isModalOpen, isPause: pause, isTop, isBottom }: AutoScrollProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    let scrollDirection: 'up' | 'down' = 'down'; // Initialize scroll direction
    let scrollInterval: NodeJS.Timeout | null = null; // Initialize scroll interval


    useEffect(() => {
        const container = containerRef.current;

        const handleMouseOver = () => {
            if (scrollInterval) {
                clearInterval(scrollInterval);
                scrollInterval = null;
            }
        };

        const handleMouseOut = () => {
            if (!scrollInterval) {
                startAutoScroll();
            }
        };

        const handleScrollToTop = () => {
            if (isTop) {
                container!.scrollTop = 0;
            }
        }

        const handleScrollToBottom = () => {
            if (isBottom) {
                container!.scrollTop = container!.scrollHeight - container!.clientHeight;
            }
        }

        if (container!.clientHeight - container!.scrollHeight <= 10) {
            handleMouseOver();
        }

        const startAutoScroll = () => {
            scrollInterval = setInterval(() => {

                const isAtTop = container!.scrollTop === 0;
                const scrollHeight = container!.scrollHeight;
                const clientHeight = container!.clientHeight;
                const isAtBottom = Math.ceil(scrollHeight - container!.scrollTop) <= (clientHeight + 5);

                if (isAtTop) {
                    // Change direction when reaching the top
                    scrollDirection = 'down';
                } else if (isAtBottom) {
                    // Change direction when reaching the bottom
                    scrollDirection = 'up';

                }
                // console.log('isAtTop', isAtTop, 'scroll height', scrollHeight, 'clientHeight', clientHeight, 'isAtBottom', isAtBottom, 'scrollDirection', scrollDirection, 'scroll top', container!.scrollTop);

                // Scroll in the current direction
                container!.scrollTop += scrollDirection === 'down' ? 2 : -1;
            }, 40); // Adjust the interval as needed
        };

        container!.addEventListener('mouseover', handleMouseOver);
        container!.addEventListener('mouseout', handleMouseOut);

        startAutoScroll(); // Start scrolling initially

        if (pause) {
            handleMouseOver();
        }
        handleScrollToTop();
        handleScrollToBottom();

        if (isModalOpen) {
            container!.addEventListener('mouseout', handleMouseOver);

        }
        console.log('isModalOpen', isModalOpen)
        return () => {
            if (scrollInterval) {
                clearInterval(scrollInterval);
            }
            container!.removeEventListener('mouseover', handleMouseOver);
            container!.removeEventListener('mouseout', handleMouseOut);

        };
    }, [isModalOpen, pause, isTop, isBottom]);

    return (
        <div ref={containerRef} style={{ ...styles, scrollBehavior: isTop || isBottom ? 'smooth' : 'inherit' }} className="scroll-container">
            {/* Your scrollable content goes here */}
            {children}
        </div>
    );
};
export default AutoScroll;