import { useDispatch, useSelector } from "react-redux";
//activity listener
import { useIdleTimer } from "react-idle-timer";
import { useLocation, useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { CRoutePaths } from "../../constants/CApp";
import CookieManager from "../../components/Cookies";
import Modal from "antd/es/modal/Modal";
import { Stack } from "@mui/material";
import "./SessionManager.style.css"

export const UseSessionManager = () => {
  const dispatch = useDispatch();      
  const history = useHistory();
  
  const [timer, setTimer] = useState("");
  const user = useSelector((slice: any) => slice.user.user);
  const [modelOpen,setModelOpen] = useState(false);

  useEffect(() => {
    if (user != null && user != undefined) {
      Timer1.start();
    } else {
      Timer1.start();
      Timer1.pause();
    }
  }, [user]);

  useEffect(() => {
    setInterval(() => {
      setTimer(
        "Timer 1: " +
        Timer1.getRemainingTime() 
      );
    }, 1000);
  }, [user]);

  const onPrompt = () => {
    // Fire a Modal Prompt
    alert("onprompt");
    console.log("onPrompt");
  };

  const onActive = () => {
    // Close Modal Prompt
    // Do some active action
    //alert("onActive")
    console.log("onActive");
  };

  const onAction = () => {
    // console.log("onAction");
  };

  const alertTimeout : any = useRef(null)
    
  const onIdleTimer1 = async () => {
    setModelOpen(true)

    alertTimeout.current  = setTimeout(()=>{
      handleCloseSession();
    },(1000 * 60 * window.config.sessionTimeOutAlert))
  };

  const handleExtendMySession = ()=>{
    setModelOpen(false);

    console.log(alertTimeout);
    clearTimeout(alertTimeout.current);
  }

  const handleCloseSession = ()=>{
    // Close Modal Prompt
    // Do some idle action like log out your user
    //alert("onIdle")
    console.log("onIdle2" + new Date());
    console.log("Logout due to the session timeout")
    history.replace(CRoutePaths.Login);
    var manager = new CookieManager();
    const deletePresentCookie = manager.deleteCookie("PH_SSO");

  }
 

  const Timer1 = useIdleTimer({
    onPrompt,
    onIdle: onIdleTimer1,
    onActive,
    onAction,
    timeout: 1000 * 60 * (window.config.sessionTimeOut - window.config.sessionTimeOutAlert),
    //timeout: 1000 * 10, 
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: true,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  return <>
  {/* <label>{timer}</label> */}
    <Modal open={modelOpen} footer={null} closeIcon={false} maskClosable={false} closable={false}>
      <Stack className="session-conts">
        <label className="session-label">Session Timeout</label>
        <label className="session-desc">Your session will be logged out after {window.config.sessionTimeOutAlert} mins</label>
      </Stack>
      <Stack direction={"row"} justifyContent={"flex-end"}>
          <button className="session-button" onClick={handleExtendMySession}>Extend my Session</button>
          <button className="session-button" onClick={handleCloseSession}>Close Session</button>
      </Stack>
    </Modal>
  </>;
};
