import React from 'react'
import ChangePasswordForm from './ChangePassword.component';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ChangePassword = () => {

    const history = useHistory();
    const user = useSelector((slice: any) => slice.user.user);

    const handleChangePwdSuccess = () => {
        history.goBack()
    }

    const handleChangePwdCancel = () => {
        history.goBack()
    }

    return (
        <div>
            <ChangePasswordForm
                isSingleColumn={false}
                emailID={user?.email}
                handleSucess={handleChangePwdSuccess}
                handleCancel={handleChangePwdCancel}
                isNeedCancel={true}
            />
        </div>
    )
}

export default ChangePassword