import React, { useEffect, useState } from "react";
import { Stack, Badge } from "@mui/material";
import { PHIcons } from "../Icons/PHIcons";
import IconWithNameDropDown, {
  IMenuList,
} from "../DropDown/IconWithNameDropDown.component";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DiscoverImage from "./discover-logo.svg";
import DiscoverImageWhite from "../../assets/Discover Logo White.svg";
import { API_SSO } from "../../api/ssoapi/SSOapi";
import { flushSync } from "react-dom";
import CookieManager from "../Cookies";
import { useHistory, useLocation } from "react-router-dom";
import { CLocalStorage } from "../../constants/CLocalStorage";
import { CRoutePaths } from "../../constants/CApp";
import './headerStyle.css'
import UserIcon from '../../assets/User icon.svg';
import BurgerIcon from '../../assets/Burger icon.svg'
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';
import { openUrl } from "../../Utils/LinkUtils.hooks";

type HeaderProps = {
  isLogin: boolean;
  user: any;
  onClickChangePwd: () => void;
};

const Header = (props: HeaderProps) => {
  const history = useHistory();
  const routePath = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState<null | HTMLElement>(null);
  const [userName, setUserName] = useState("");
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsMenuOpen(event.currentTarget);
  };

  useEffect(() => {
    var userName = "";
    if (props.user?.userName) {
      userName = props.user?.userName;
    }
    setUserName(userName);
  }, [props.user]);
  const handleClose = () => {
    setIsMenuOpen(null);
  };

  const handleClickMyProfile = () => {
    setIsMenuOpen(null);
    history.push(CRoutePaths.MyProfile);
  }


  const handleClickApplication = () => {
    setIsMenuOpen(null);
    history.push("/applications");
  }

  const handleClickChangePassword = () => {
    setIsMenuOpen(null);
    history.push("/change-password");
    // props.onClickChangePwd();
  };

  const onLogOut = async () => {
    const email = sessionStorage.getItem(CLocalStorage.Keys.Email);
    var token = sessionStorage.getItem(CLocalStorage.Keys.Token);
    try {
      //setLoading(true);

      var res = await API_SSO.SSOLogout(email ? email : "", token ? token : "");

      if (res.data) {
        history.replace(CRoutePaths.Login);
        var manager = new CookieManager();
        const deletePresentCookie = manager.deleteCookie("PH_SSO");
      } else {
        console.error("API response indicates failure.");
      }
    } catch (error) {
      console.error("Form validation error: ", error);
    }
    setIsMenuOpen(null);
  };

  const Logout = () => {
    return (
      <>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ minWidth: "120px" }}
        >
          <span>Log Out</span>
          <span className="logout-icon">
            {PHIcons.LogoutIcon}
          </span>
        </Stack>
      </>
    );
  };

  const handleClickHelp = () => {
    setIsMenuOpen(null);
    openUrl({
      url: window.config.helpLink,
      isNewTab: true,
      isHistoryNeed: true
    })
  }

  const menuList: IMenuList[] = [
    {
      name: "My Profile",
      route: "/myprofile",
      onClick: handleClickMyProfile,
    },
    {
      name: "Help",
      route: "/change-password",
      onClick: handleClickHelp,
    }
  ];

  const HeaderLabelStyle: React.CSSProperties = {
    fontFamily: "Open Sans",
    fontSize: "19px",
    fontWeight: "500",
    color: "#ec6c2a",
  };

  const imgStyle: React.CSSProperties = {
    height: "30px",
    cursor: "pointer"
  };



  return (
    <>
      <Stack className={"header-section " + (props.user ? " header-section-bgd-grad" : "header-section-bgd-full")}
        sx={{
          minHeight: "50px",
        }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} columnGap={2} alignItems={"center"}>
          <img className="discover-logo" onClick={handleClickApplication} src={DiscoverImageWhite} alt="discover" style={imgStyle} />
          <label className="header-label" style={HeaderLabelStyle}>Product Certification Gateway</label>
        </Stack>

        {props.isLogin && (
          <>
            <Stack columnGap={2} alignItems={"center"} direction={"row"} className="header-right">
              <span className="user-name">{userName}</span>


              <IconWithNameDropDown
                locationPath={routePath.pathname}
                handleClick={handleClick}
                handleClose={handleClose}
                icon={
                  <div className="user-icon" >
                    <img src={UserIcon} alt="User Icon" />
                  </div>

                  // <div className="burger-icon-container">
                  //   <div className="burger-icon">

                  //     <img src={LogoutIcon} alt="Burger icon" />
                  //     <LogoutIcon onClick={onLogOut}/>                       
                  //   </div>
                  // </div>
                }
                isMenuOpen={isMenuOpen}
                menuList={menuList}
                userName={userName}
              />
              <Badge badgeContent={6} color="warning" >
                <NotificationsIcon sx={{ fontSize: "24px", color: "white" }} className="notification-icon" />
              </Badge>
              {/* <HelpIcon className="head-help-icon" titleAccess="Help" onClick={handleClickHelp}/>                 */}

              <div className="burger-icon-container">
                <div className="burger-icon">
                  {/* <img src={LogoutIcon} alt="Burger icon" /> */}
                  <LogoutIcon onClick={onLogOut} />
                </div>
              </div>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default Header;
