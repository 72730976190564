import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ISSOUser{
    userName : string;
    email : string;
    accessApplications : ISSOUserApplicationAccess[];
    isAdmin : boolean;
    isDiscoverUser : boolean;
    lastLogin : string;
    status : string;
    createdOn : string;
    updatedOn : string;
    lastUsedApplicationId : string;
    isOtpEnabled : boolean;
    authentication : ISSOAuthentication;
    isSSOLoggedIn : boolean;
    ccEmailIds : string[];
}

export interface ISSOUserApplicationAccess{
    applicationID : string;
    state : string;
    isMailSent : boolean;
}

export interface ISSOAuthentication{
    keyType : string;
    state : string;
}

export interface IApplicationSetup{
    id : string;
    name : string;
    image : string;
    platform : string;
    displayOrder : number;
    status : number;
    displayState : string;
    createdOn : string;
    lastUpdatedOn: string;    
    setupUrl : string;
    //public AuthentificationConfig AuthConfig { get; set; } = new AuthentificationConfig();
    //public string? APIClass { get; set; }
    //public Dictionary<string, string> JWTConfigurations { get; set; } = new Dictionary<string, string>();
    clientId : string;
}

interface IUserSliceState {
    user: ISSOUser | null,
    applications : IApplicationSetup[]
}

const initialState: IUserSliceState = {
    user: null,
    applications : []
}

export const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        UpdateUser: (state, action: PayloadAction<{ user: any }>) => {
            console.log(action.payload.user);
            return { ...state, user: { ...state.user, ...action.payload.user } };
        },
        UpdateApplications: (state, action: PayloadAction<{ applications: IApplicationSetup[] }>) => {
            console.log(action.payload.applications);
            state.applications = action.payload.applications;
            //return { ...state, applications: { ...state.user, ...action.payload.user } };
        }
    }
})

export const { UpdateUser,UpdateApplications } = UserSlice.actions;
export default UserSlice.reducer