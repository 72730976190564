import { Stack } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useHistory } from "react-router-dom";
import './MyProfile.style.css'
import GLabel from "../html-elements/GLabel.component";
import { useSelector } from "react-redux";
import { RootState } from "../../redux-store/store";
import { useEffect, useState } from "react";
import { CRoutePaths } from "../../constants/CApp";

console.log("MyProfile")

const MyProfilePage = (props: {  
  handleClose: () => void;
}) => {
  const history = useHistory();
  const user = useSelector((slice : RootState)=> slice.user.user);
  const applications = useSelector((slice : RootState)=> slice.user.applications);

  const [apps,setApps] = useState<string[]>([])
const [pwdDot, setPwdDot] = useState([1,2,3,4,5,6,7,8])

  const handleClickCancel = () => {
    history.goBack();
  }

  useEffect(()=>{

    var lApps : string[] = []
   
    if(user){
        applications.map((item) => {
           var isAccess = user.accessApplications.find(x => x.applicationID == item.id);

           if(isAccess && item.displayState == "VIEW"){
            lApps.push(item.name);
           }
        })            
    }

    setApps(lApps)    
  },[applications,user])

  const handleChangePassword = ()=>{
     history.push(CRoutePaths.ChangePassword)
  }
  
  return (
    <>
    <div className="myprofile-cont">
        <Stack direction={"row"} justifyContent={"space-between"}>
            <label className="myprofile-title">My Profile</label>
            <CloseIcon onClick={handleClickCancel}></CloseIcon>
        </Stack>
        <div className="myprofile-content">
        {
        user && <Stack direction={'row'} useFlexGap flexWrap="wrap" >
            <Stack className="myprofile-conts"  direction={'column'} justifyContent={'flex-start'} spacing={3}>
                <Stack direction={'column'} justifyContent={'flex-start'}>
                    <GLabel label="User Name" variant="regular"/>
                    <GLabel label={user.userName} variant="regular" color={true}/>
                </Stack>
                <Stack direction={'column'} justifyContent={'flex-start'}>
                    <GLabel label="Email Address" variant="regular"/>
                    <GLabel label={user.email} variant="regular" color={true}/>                    
                </Stack>
                {user.ccEmailIds?.length > 0 && <Stack direction={'column'} justifyContent={'flex-start'}>
                    <GLabel label="CC Email Address" variant="regular"/>
                    {
                        user.ccEmailIds.map((cc)=>{
                            return <GLabel label={cc} variant="regular" color={true}/>
                        })
                    }                    
                </Stack>}
                <Stack direction={'column'} justifyContent={'flex-start'}>
                    <GLabel label="Password" variant="regular"/>
                    <span>
                    {
                        pwdDot.map((f)=>{
                            return <FiberManualRecordIcon className="pwd-circle" />
                        })
                    }                    
                    <EditIcon onClick={handleChangePassword}/>
                    </span>
                </Stack>
                                
        </Stack>
        <Stack className="myprofile-conts" direction={'column'} justifyContent={'flex-start'} spacing={3}>
            <Stack direction={'column'} justifyContent={'flex-start'}>
                    <GLabel label="Access Applications" variant="regular"/>
                    {
                        apps.map((app)=>{
                            return <GLabel label={app} variant="regular" color={true}/>
                        })
                    }
                    
                </Stack>
        </Stack>
     </Stack>
    }
        </div>
    </div>
        
     
    </>
  );
};

export default MyProfilePage;
