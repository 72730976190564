import { IconButton, Stack } from "@mui/material";
import GInput from "../html-elements/GInput.component";
import { PHIcons } from "../Icons/PHIcons";
import discoverImg from "../../assets/discover.svg";
import { useEffect, useState } from "react";
import { post } from "../../Utils/apiCall";
import Constants from "../Constants";
import { useHistory } from "react-router-dom";
import CookieManager from "../Cookies";
import { API_SSO } from "../../api/ssoapi/SSOapi";
import { Modal } from "antd";
import { CLocalStorage } from "../../constants/CLocalStorage";
import './ChangePassword.style.css'

export const submitButtonStyle: React.CSSProperties = {
  width: "400px",
  height: "48px",
  borderRadius: "6px",
  fontFamily: "DiscoverSans-Medium",
  fontSize: "16px",
  fontWeight: "500",
  color: "white",
  backgroundColor: "#EC6C2A",
  border: "none",
};

export const cancelButtonStyle: React.CSSProperties = {
  width: "400px",
  height: "48px",
  borderRadius: "6px",
  fontFamily: "DiscoverSans-Medium",
  fontSize: "16px",
  // fontWeight: "500",
  color: "black",
  backgroundColor: "white",
  border: "1px solid grey",
};

export const submitDisableButtonStyle: React.CSSProperties = {
  width: "400px",
  height: "48px",
  borderRadius: "6px",
  fontFamily: "DiscoverSans-Medium",
  fontSize: "16px",
  fontWeight: "500",
  color: "white",
  backgroundColor: "#EC6C2A",
  border: "none",
  pointerEvents: "none",
  opacity: "0.6",
};

const inputDivStyle: React.CSSProperties = {
  // minWidth: "400px",
  marginBottom: "16px",
};

const correctColor = "green";
const wrongColor = "#f00";

console.log("changepwd");
const ChangePasswordForm = (props: {
  isSingleColumn: boolean;
  emailID: string;
  handleSucess: () => void;
  isNeedCancel: boolean;
  handleCancel: () => void;
}) => {
  const history = useHistory();

  const [isModalOpen, setModalOpen] = useState(false);
  const [oldPwd, setOldPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confPwd, setConfPwd] = useState("");

  const [showOldPwdText, setShowOldPwdText] = useState(false);
  const [showNewPwdText, setShowNewPwdText] = useState(false);
  const [showConfPwdText, setShowConfPwdText] = useState(false);

  const [pwdData, setPwdData] = useState({
    oldPwd: {
      value: "",
      text: "",
      error: false,
      name: "oldPwd",
    },
    newPwd: {
      value: "",
      text: "",
      error: false,
      name: "newPwd",
    },
    confirmPwd: {
      value: "",
      text: "",
      error: false,
      name: "confirmPwd",
    },
  });

  const [confirmPwdErr, setConfirmErr] = useState({
    error: false,
    text: "",
  });

  const onLoginSuccess = (email: any) => {
    sessionStorage.setItem(CLocalStorage.Keys.Email, email);
    localStorage.setItem(CLocalStorage.Keys.Email, email);

    console.log(sessionStorage.getItem(CLocalStorage.Keys.Email));
    history.push(`/applications?email=${email}`);
  };

  const handleOnchangeFormData = (e: any) => {
    //var data: any = JSON.parse(JSON.stringify(pwdData));
    //data[e.target.name].value = e.target.value;
    // var data = pwd;
    // var idx = parseInt(e.target.name);
    // data[idx] = e.target.value;
    // setPwd(data);
    //setPwdData(data);
    //console.log(data);
  };

  const handleOtpSubmit = async (e: any) => {
    e.preventDefault();
    var res = await API_SSO.ChangePassword(props.emailID, oldPwd, newPwd);
    if (res.data.status.includes("SUC")) {
      setModalOpen(true);
      setTimeout(()=>{
        handleOk();
      },3000)
    } else {
      var data = { error: true, text: "" };
      data.error = true;
      data.text = "Password not changed";

      if (res.data.status.includes("ERR")) {
        res.data.errors?.map((error: any) => {
          if (error?.message == "oldPassword") {
            data.text = "Current password is incorrect";
          }
        });
      }

      setConfirmErr(data);
    }
  };

  const handleClickCancel = () => {
    props.handleCancel();
  }

  const condition1 = newPwd.length >= 8 ? correctColor : wrongColor;
  const condition2 = newPwd?.match(/[A-Z]/) ? correctColor : wrongColor;
  const condition3 = newPwd?.match(/[a-z]/) ? correctColor : wrongColor;
  const condition4 = newPwd?.match(/[`~!@#$%\^&*()+=|;:'",.<>\/?\\\-]/)
    ? newPwd?.match(/[\d]/)
      ? correctColor
      : wrongColor
    : wrongColor;
  const condition5 =
    newPwd === confPwd && newPwd !== "" ? correctColor : wrongColor;
  const condition6 = oldPwd.length > 0 && newPwd.length > 0 && newPwd != oldPwd ? correctColor : wrongColor;

  const isValidData =
    condition1 != wrongColor &&
      condition2 != wrongColor &&
      condition3 != wrongColor &&
      condition4 != wrongColor &&
      condition5 != wrongColor &&
      condition6 != wrongColor
      ? true
      : false;

  const handleOk = () => {
    props.handleSucess();
  };

  const handleCancel = () => {
    props.handleSucess();
  };
  return (
    <>
      <Modal
        title={"Information"}
        open={isModalOpen}
        closeIcon={false}
        onOk={handleOk}
        // style={style}
        onCancel={handleCancel}
        footer={[]}
        width={600}
        maskClosable={false}
        //afterClose={afterClose}
        //keyboard={keyboard}
        //closable={closable}

        centered={true}
      >
        <label>Password updated successfully</label>
      </Modal>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{
          height:"100%"
        }}
      >

        <div className={`change-password-container ${props.isSingleColumn ? "single-column" : "double-column"}`} >
          <div className="form-section">
            <Stack direction={"row"} justifyContent={"center"} sx={inputDivStyle}>
              <img style={{ marginTop: "30px" }} src={discoverImg} />
            </Stack>
            <div className="form-input" style={inputDivStyle}>
              <GInput
                type={showOldPwdText ? "text" : "password"}
                name="0"
                // value={pwdData.oldPwd.value}
                value={oldPwd}
                error={pwdData.oldPwd.error}
                helperText={pwdData.oldPwd.text}
                onChange={(e) => {
                  setOldPwd(e.target.value.trim());
                }}
                label="Current Password"
                icons={{
                  end: (
                    <span
                      onClick={() => {
                        setShowOldPwdText(!showOldPwdText);
                      }}
                      style={{
                        color: "#EC6C2A",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      {showOldPwdText ? PHIcons.EyeOff : PHIcons.EyeOn}
                    </span>
                  ),
                }}
              />
            </div>
            <div className="form-input" style={inputDivStyle}>
              <GInput
                type={showNewPwdText ? "text" : "password"}
                name="1"
                value={newPwd}
                error={pwdData.newPwd.error}
                helperText={pwdData.newPwd.text}
                onChange={(e) => {
                  setNewPwd(e.target.value.trim());
                }}
                label="New Password"
                icons={{
                  end: (
                    <span
                      onClick={() => {
                        setShowNewPwdText(!showNewPwdText);
                      }}
                      style={{
                        color: "#EC6C2A",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      {showNewPwdText ? PHIcons.EyeOff : PHIcons.EyeOn}
                    </span>
                  ),
                }}
              />
            </div>

            <div className="form-input" style={inputDivStyle}>
              <GInput
                type={showConfPwdText ? "text" : "password"}
                name="2"
                value={confPwd}
                error={confirmPwdErr.error}
                helperText={confirmPwdErr.text}
                onChange={(e) => {
                  setConfPwd(e.target.value.trim());
                }}
                label="Confirm New Password"
                icons={{
                  end: (
                    <span
                      onClick={() => {
                        setShowConfPwdText(!showConfPwdText);
                      }}
                      style={{
                        color: "#EC6C2A",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      {showConfPwdText ? PHIcons.EyeOff : PHIcons.EyeOn}
                    </span>
                  ),
                }}
              />
            </div>
          </div>
          <div className="button-section">
            <Stack spacing={2} direction={"column"} >
              <button className="change-password-button"
                onClick={handleOtpSubmit}
                disabled={!isValidData}
                style={isValidData ? submitButtonStyle : submitDisableButtonStyle}
              >
                Change Password
              </button>
              {props.isNeedCancel && <button
                className="cancel-button"
                onClick={handleClickCancel}
                disabled={false}
                style={cancelButtonStyle}
              >
                Cancel
              </button>
              }
            </Stack>
          </div>
          <div className="condition-section" >
            <div className="valDiv cha_pwd-con" style={{ marginBottom: "16px" }}>
              <p style={{ fontWeight: "bold", fontFamily: "DiscoverSans-Bold" }}>
                All checkmarks must turn green, password must have:
              </p>
              <div style={{ color: condition1 }} className="mt-[8px] flex ">
                {/* <AiOutlineCheckCircle /> */}
                <span className="password-instructions">At least 8 characters</span>
              </div>
              <div style={{ color: condition2 }} className="mt-[8px] flex ">
                {" "}
                {/* <AiOutlineCheckCircle />{" "} */}
                <span className="password-instructions">
                  At least 1 uppercase letter
                </span>
              </div>
              <div style={{ color: condition3 }} className="mt-[8px] flex ">
                {" "}
                {/* <AiOutlineCheckCircle />{" "} */}
                <span className="password-instructions">
                  At least 1 lowercase letter
                </span>
              </div>
              <div style={{ color: condition4 }} className="mt-[8px] flex ">
                {" "}
                {/* <AiOutlineCheckCircle />{" "} */}
                <span className="password-instructions">
                  At least 1 number and at least 1 special character
                </span>
              </div>
              <div style={{ color: condition5 }} className="mt-[8px] flex ">
                {" "}
                {/* <AiOutlineCheckCircle />{" "} */}
                <span className="password-instructions">
                  New Password equals Confirm Password
                </span>
              </div>
              <div style={{ color: condition6 }} className="mt-[8px] flex ">
                {" "}
                {/* <AiOutlineCheckCircle />{" "} */}
                <span className="password-instructions">
                  Current Password not equals New Password
                </span>
              </div>
            </div>
          </div>
        </div>
      </Stack>
    </>
  );
};

export default ChangePasswordForm;
