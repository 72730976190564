


import { Modal } from 'antd';
import React from 'react';

type AntdModalProps = {
    modalBody?: any;
    isModalOpen: boolean;
    handleOk?: () => void;
    handleCancel?: () => void;
    style?: any;
    width: number;
    maskClosable?: boolean;
    afterClose?: () => void;
    keyboard: boolean;
    centered: boolean;
    closeIcon?: boolean;
    title?: string;
    closable?: boolean

}


const AntdModal = ({ modalBody, closeIcon, handleCancel, handleOk, isModalOpen, style, width, maskClosable, afterClose, keyboard, centered, title, closable }: AntdModalProps) => {
    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const showModal = () => {
    //     setIsModalOpen(true);
    // };

    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };

    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };  These are the things that need to be their in parent component

    return (
        <>

            <Modal title={title}
                open={isModalOpen}
                closeIcon={closeIcon}
                onOk={handleOk}
                style={style}
                onCancel={handleCancel}
                footer={[]}
                width={width}
                maskClosable={maskClosable}
                afterClose={afterClose}
                keyboard={keyboard}
                closable={closable}

                centered={centered} >

                {modalBody}
            </Modal>
        </>
    );
};

export default AntdModal;