import { Stack } from "@mui/material";
import { PHIcons } from "../Icons/PHIcons";
import "./Notification.styles.css";
import { API_TSE } from "../../api/ssoapi/SSOapi";
import { API_Notify } from "../../api/notifyapi/Notifyapi";

type NotificationModalBodyProps = {
  header: string;
  description: string;
  actions: {
    close: () => void;
  };
  files: IFileData[];
};

export const NotificationModalBody = ({
  header,
  actions,
  description,
  files,
}: NotificationModalBodyProps) => {
  const downloadFile = () => {};
  return (
    <>
      <Stack direction={"column"} rowGap={2}>
        <Stack className="notification-modal-container" direction={"column"}>
          <label>{header}</label>
          <p style={{margin : "15px 0 15px 0"}}> {description}</p>
        </Stack>
        {files?.length > 0 && (
          <>
            {files?.map((file) => (
              <DownloadfileComponent data={file} download={downloadFile} />
            ))}
          </>
        )}
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <button className="close-button" onClick={actions.close}>
            Close
          </button>
        </Stack>
      </Stack>
    </>
  );
};

export interface IFileData {
  fileName: string;
  fileSize: string;
  filePath: string;
}

type DownloadfilecomponentProps = {
  data: IFileData;
  download: () => void;
};

export const DownloadfileComponent = ({
  data,
  download,
}: DownloadfilecomponentProps) => {
  const handleDownload = () => {
    API_Notify.DownloadFile(data.filePath);
  };
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        className="download-container"
        alignItems={"center"}
      >
        <Stack direction="row" columnGap={1} alignItems={"center"}>
          <span className="description-icon">
            {PHIcons.DescriptionOutlinedIcon}
          </span>
          <Stack direction="column">
            <label className="file-name">{data.fileName}</label>
            <label className="file-size">{data.fileSize}</label>
          </Stack>
        </Stack>

        <span className="arrow-downward-icon" onClick={handleDownload}>
          {PHIcons.ArrowDownwardIcon}
        </span>
      </Stack>
    </>
  );
};
