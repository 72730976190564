import { AES, enc } from 'crypto-js';
import { CLocalStorage } from '../../constants/CLocalStorage';



export default class CookieManager {
    private static cookiePassword = "PHJWTKEY@20230914#";
    private static cookieName = "PH_SSO";
  
    createCookie = async (cookieValue: string) => {
      console.log("cookie mananger");
      const expirationTime = new Date();
      expirationTime.setTime(expirationTime.getTime() + (5 * 60 * 1000));
      const expires = `expires=${expirationTime.toUTCString()}`;
      const cipherText = await this.encryptCookie(cookieValue);
      document.cookie = `${CookieManager.cookieName}=${escape(cipherText)}`;
    };
  
    encryptCookie = async (cookieValue: string) => {
      const encryptedCipherText = AES.encrypt(cookieValue, CookieManager.cookiePassword).toString();
      return encryptedCipherText;
    };
  
    decryptCookie(encryptedText: string)  {
      let decryptedText = '';
      if (encryptedText !== null) {
        const bytes = AES.decrypt(encryptedText, CookieManager.cookiePassword);
        decryptedText = bytes.toString(enc.Utf8);
      }
      return decryptedText;
    };
  
    deleteCookie = async (cookieName: string) => {
      const date = new Date();
      date.setTime(date.getTime());
      const expires = 'expires=' + date.toUTCString();
      sessionStorage.removeItem(CLocalStorage.Keys.Email);
      sessionStorage.removeItem(CLocalStorage.Keys.Token);

      document.cookie = cookieName + '=' + ';' + expires + ';';
    };
  
    getCookie(jsonParse = true) {
      const name = CookieManager.cookieName + '=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        console.log(c);
        if (c.indexOf(name) === 0) {

          const encryptedCookie = c.substring(name.length, c.length);
          if (encryptedCookie !== null) {
            const decryptedCookie =  this.decryptCookie(encryptedCookie);
            console.log(decryptedCookie);
            if (decryptedCookie !== null && decryptedCookie !== '') {
              return jsonParse ? JSON.parse(decryptedCookie) : decryptedCookie;
            }
          }
        }
      }
      return '';
    };
  }