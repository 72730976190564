import { NotifyApi } from "../http-common";

export interface IGetNotificationReq {
  userID: string;
  roles: string[];
  partnerID: string;
}
/**
 * User Account Controller
 * It was written based on the API document
 */
export const API_Notify = {
  GetNotificationByUserID: (req: IGetNotificationReq) => {
    return NotifyApi.post("api/Notification/get-notificationBy-userType", req);
  },
  DownloadFile : async (filePath : string)=>{    

    var fileNameList = filePath.split("\\");
      var fileName = fileNameList.at(-1);

      const response = await NotifyApi.post("api/Notification/Download-file", null,{
      params : {
        filePath : filePath
      },
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
      }
    });
    
      console.log(response);
      let blob = new Blob([response.data], {
        type: response.headers["Content-type"],
      });
      const href = URL.createObjectURL(blob);
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName ? fileName : "file.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    
  }
};
