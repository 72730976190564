
import React, { useEffect } from 'react';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';
import Header from '../Header/Header.component';
import Footer from '../Footer';
import { CRoutePaths } from '../../constants/CApp';
import { CLocalStorage } from '../../constants/CLocalStorage';
import { API_SSO } from '../../api/ssoapi/SSOapi';
import { UpdateUser } from '../../slices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { UseSessionManager } from '../../browser/session/SessionManager';


interface PrivateRouteProps {
  component: React.FC<RouteComponentProps>;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ path, component: Component, ...rest }) => {
  // const [token, setToken] = useState("");
  // const isLoggedIn =  !!token;
  // console.log(token);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(window.location.search);
  //   const oktaToken = searchParams.get('token');
  //   console.log(oktaToken);
  //   if (oktaToken) {
  //     setToken(oktaToken);
  //   }
  // }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((slice: any) => slice.user.user);
  console.log(user)


  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    const email = sessionStorage.getItem(CLocalStorage.Keys.Email);
    console.log(email);

    try {
      var res = await API_SSO.GetUser(email ? email : "");

      if (res.data?.status?.includes("SUC") && res.data?.data) {
        console.log(res.data.data)

        dispatch(UpdateUser({ user: res.data.data }))

      }
    } catch (error) {
      console.error("Form validation error: ", error);
      history.replace(CRoutePaths.Login)
    }
  };



  return (
    <>
    <UseSessionManager />
      <Header
        isLogin={true}
        user={user}
        onClickChangePwd={() => {
          //setChanPwd(true);
        }}
      />
      <div className="main-container">
        <Route
          {...rest}
          render={(props) => <Component {...props} />
          }
        />
      </div>
      <Footer />
    </>
  );
};

export default PrivateRoute;
