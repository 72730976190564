import { Stack } from "@mui/material";
import { Modal } from "antd";
import { useState } from "react";
import AntdModal from "../Modal/AntdModal.component";
import {
  IFileData,
  NotificationModalBody,
} from "./NotificationModal.component";
import "./Notification.styles.css";
import ReadMore, { IReadMoreStyle } from "../ReadMore/ReadMore.component";

type NotificationDivStyleProps = {
  header: string;
  description: string;
  files: IFileData[];
  getModalIsOpen?: (isModal: boolean) => void;
};

const NotificationDivStyle = ({
  header,
  description,
  files,
  getModalIsOpen,
}: NotificationDivStyleProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
    getModalIsOpen?.(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    getModalIsOpen?.(false);
  };

  const readmoreStyle: IReadMoreStyle = {
    paragraph: {
      fontFamily: 'DiscoverSans-Medium',
      fontSize: '16px',
      lineHeight: '22px',
      display: 'flex',
      flexDirection: 'column'
    },
    span: {
      fontSize: '14px',
      color: 'blue'
    }
  }

  return (
    <>
      <Stack
        onClick={showModal}
        direction={"column"}
        rowGap={1}
        className="notification-div-container"
      >
        <h5>{header}</h5>
        <ReadMore
          text={description}
          styles={readmoreStyle}
          isReadMore={isReadMore}
          sliceText={{ startIdx: 0, endIdx: 69 }}
        />
      </Stack>

      <AntdModal
        centered={true}
        isModalOpen={isModalOpen}
        keyboard={false}
        width={600}
        closable={false}
        closeIcon={false}
        maskClosable={false}
        modalBody={
          <NotificationModalBody
            header={header}
            description={description}
            actions={{
              close: handleCancel,
            }}
            files={files}
          />
        }
      />
    </>
  );
};

export default NotificationDivStyle;
