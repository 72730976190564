import { useState } from "react";


export interface IReadMoreStyle {
    paragraph: React.CSSProperties;
    span: React.CSSProperties;
}

export interface IReadMoreSlice {
    startIdx: number;
    endIdx: number;
}

type ReadMoreProps = {
    text: string;
    styles?: IReadMoreStyle;
    isReadMore: boolean;
    toggleReadMore?: () => void;
    sliceText: IReadMoreSlice;
}

const ReadMore = ({ text, styles, isReadMore, toggleReadMore, sliceText }: ReadMoreProps) => {


    return (
        <>
            <p style={styles?.paragraph}>
                {isReadMore ? text.slice(sliceText.startIdx, sliceText.endIdx) : text}
                <span
                    onClick={toggleReadMore}
                    style={styles?.span}
                >
                    {isReadMore ? "Read More…" : "Show Less"}
                </span>
            </p>
        </>
    )
}


export default ReadMore