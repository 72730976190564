import { useEffect, useState } from "react"
import IconWithNameDropDown, { IMenuList } from "./IconWithNameDropDown.component"
import { PHIcons } from "../Icons/PHIcons"
import Header from "../Header/Header.component"
import Notification from "../Notification/Notification.component"
import GInput from "../html-elements/GInput.component"
import LoginForm from "../Login/LoginForm.component"
import AutoScroll from "../AutoScroll/AutoScroll.component"
import ApplicationCard from "../ApplicationList"


const Test = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<null | HTMLElement>(null)


    const [data, setData] = useState({
        price: '',
        value: ''
    })

    const onChangeHandler = (e: any) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }




    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setIsMenuOpen(event.currentTarget)
    }

    const handleClose = () => {
        setIsMenuOpen(null)
    }

    const onChangePassword = () => {
        setIsMenuOpen(null)
    }

    const onLogOut = () => {
        setIsMenuOpen(null)
    }





    const menuList: IMenuList[] = [
        {
            name: "Change Password",
            route: "/change-password",
            onClick: onChangePassword
        },
        {
            name: 'Log Out',
            route: "",
            onClick: onLogOut
        }
    ]

    useEffect(() => {
        console.log('price', data.price)
    }, [data.price])

    useEffect(() => {
        console.log('test component called')
    }, [])

    return (
        <>
            <div style={{ padding: '20px' }}>

                {/* <Header />

                <div style={{ display: 'grid' }}>
                    <Notification />
                </div> */}

                {/* <GInput name="price" value={data.price} onChange={onChangeHandler} label="Price" /> */}
                {/* <input name="price" value={data.price} onChange={onChangeHandler} /> */}
                {/* <LoginForm /> */}
                {/* <Header />
                <Notification /> */}
                <Notification />

                {/* <LoginForm /> */}
                {/* <AutoScroll>
                    <div style={{ height: '400px' }}>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas illo blanditiis minima vero natus dolor error. Quo fugit perferendis et, impedit aliquid voluptates accusantium voluptatum. Doloremque doloribus corrupti consequatur aspernatur, ab iure expedita omnis praesentium quasi dolore ipsam ad cumque quae et cum exercitationem nihil alias incidunt tempora sequi optio facilis, magni consequuntur. Ipsa, veritatis repellat. Itaque, totam quo perferendis soluta cupiditate hic tenetur odit, quia accusamus atque similique repellat vitae voluptatibus quos exercitationem sunt beatae, nihil incidunt inventore. Perferendis voluptates voluptate alias nemo ex cupiditate saepe amet quasi, deserunt eius, ea harum! Dolores voluptatum nesciunt temporibus, necessitatibus atque accusamus?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas illo blanditiis minima vero natus dolor error. Quo fugit perferendis et, impedit aliquid voluptates accusantium voluptatum. Doloremque doloribus corrupti consequatur aspernatur, ab iure expedita omnis praesentium quasi dolore ipsam ad cumque quae et cum exercitationem nihil alias incidunt tempora sequi optio facilis, magni consequuntur. Ipsa, veritatis repellat. Itaque, totam quo perferendis soluta cupiditate hic tenetur odit, quia accusamus atque similique repellat vitae voluptatibus quos exercitationem sunt beatae, nihil incidunt inventore. Perferendis voluptates voluptate alias nemo ex cupiditate saepe amet quasi, deserunt eius, ea harum! Dolores voluptatum nesciunt temporibus, necessitatibus atque accusamus?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas illo blanditiis minima vero natus dolor error. Quo fugit perferendis et, impedit aliquid voluptates accusantium voluptatum. Doloremque doloribus corrupti consequatur aspernatur, ab iure expedita omnis praesentium quasi dolore ipsam ad cumque quae et cum exercitationem nihil alias incidunt tempora sequi optio facilis, magni consequuntur. Ipsa, veritatis repellat. Itaque, totam quo perferendis soluta cupiditate hic tenetur odit, quia accusamus atque similique repellat vitae voluptatibus quos exercitationem sunt beatae, nihil incidunt inventore. Perferendis voluptates voluptate alias nemo ex cupiditate saepe amet quasi, deserunt eius, ea harum! Dolores voluptatum nesciunt temporibus, necessitatibus atque accusamus?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas illo blanditiis minima vero natus dolor error. Quo fugit perferendis et, impedit aliquid voluptates accusantium voluptatum. Doloremque doloribus corrupti consequatur aspernatur, ab iure expedita omnis praesentium quasi dolore ipsam ad cumque quae et cum exercitationem nihil alias incidunt tempora sequi optio facilis, magni consequuntur. Ipsa, veritatis repellat. Itaque, totam quo perferendis soluta cupiditate hic tenetur odit, quia accusamus atque similique repellat vitae voluptatibus quos exercitationem sunt beatae, nihil incidunt inventore. Perferendis voluptates voluptate alias nemo ex cupiditate saepe amet quasi, deserunt eius, ea harum! Dolores voluptatum nesciunt temporibus, necessitatibus atque accusamus?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas illo blanditiis minima vero natus dolor error. Quo fugit perferendis et, impedit aliquid voluptates accusantium voluptatum. Doloremque doloribus corrupti consequatur aspernatur, ab iure expedita omnis praesentium quasi dolore ipsam ad cumque quae et cum exercitationem nihil alias incidunt tempora sequi optio facilis, magni consequuntur. Ipsa, veritatis repellat. Itaque, totam quo perferendis soluta cupiditate hic tenetur odit, quia accusamus atque similique repellat vitae voluptatibus quos exercitationem sunt beatae, nihil incidunt inventore. Perferendis voluptates voluptate alias nemo ex cupiditate saepe amet quasi, deserunt eius, ea harum! Dolores voluptatum nesciunt temporibus, necessitatibus atque accusamus?</p>

                    </div>
                </AutoScroll> */}
            </div>
        </>
    )
}

export default Test