import { IconButton, Stack } from "@mui/material";
import GInput from "../html-elements/GInput.component";
import { PHIcons } from "../Icons/PHIcons";
import discoverImg from "../../assets/discover.svg";
import { useEffect, useRef, useState } from "react";
import { post } from "../../Utils/apiCall";
import Constants from "../Constants";
import { useHistory  } from "react-router-dom";
import CookieManager from "../Cookies";
import { API_SSO, SetSSOToken } from "../../api/ssoapi/SSOapi";
import { Modal } from "antd";
import { getRememberMeDetails, performRememberMe } from "../../Utils/RememberMe.hooks"
import { CLocalStorage } from "../../constants/CLocalStorage";
import { CRoutePaths } from "../../constants/CApp";
import { useDebounce } from "../../hooks/debounce";

export const submitButtonStyle: React.CSSProperties = {
  width: "360px",
  height: "48px",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: "500",
  color: "white",
  backgroundColor: "#EC6C2A",
  border: "none",
};

export const submitDisableButtonStyle: React.CSSProperties = {
  width: "360px",
  height: "48px",
  borderRadius: "6px",
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: "500",
  color: "white",
  backgroundColor: "#EC6C2A",
  border: "none",
  pointerEvents: "none",
  opacity: "0.6",
};

const inputDivStyle: React.CSSProperties = {
  minWidth: "360px",
  marginBottom: "16px",
};

const disabledDivStyle: React.CSSProperties = {
  minWidth: "360px",
  marginBottom: "16px",
  pointerEvents: "none",
  opacity: "0.6",
};
const rememberMeStyle: React.CSSProperties = {
    textAlign: 'left',
    width: '100%',
    paddingBottom: '20px',
    fontFamily: "Open Sans",
    fontWeight: '400'
}
const checkBoxStyle: React.CSSProperties = {
    width: '20px',
    height: '20px',
}
const spanStyle: React.CSSProperties = {
  color: "#EC6C2A",
  fontSize: "12px",
  fontWeight: "400",
  fontFamily: "Noto Sans",
  cursor: "pointer",
};

const forgotspanStyle: React.CSSProperties = {
  color: "#EC6C2A",
  fontSize: "13px",
  fontWeight: "600",
  fontFamily: "Noto Sans",
  cursor: "pointer",
};

const AuthResults = {
  INVALID: 0,
  DONE: 1,
  OTP_AUTH_NEED: 2,
  SSO_AUTH_NEED: 3,
  PASSWORD_RESET_NEED: 4,
  SERVER_ERROR: 100
};

interface ILoginData {
  userName : string;
  password : string;
  otp : string
}

const LoginForm = (props: { onEnableChangePwd: (emailID: string) => void }) => {
  const history = useHistory();
  let clientId: string =
    "U2FsdGVkX19oz6u1m94COncZ/FTnbPZhVUWw0vJhEED2/s3RuCRLhTu9SpDUIABr";
  
    const [isRememberme,SetRememberMe] = useState(false);
    const [loginData, setLoginData] = useState<ILoginData>({
    userName: "",
    password: "",
    otp: "",    
  });

  const [helperText, setHelperText] = useState({
    userName: {
      error: false,
      text: "",
    },
    password: {
      error: false,
      text: "",
    },
    otp: {
      error: false,
      text: "",
    },
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [isCookiePresent, setIsCookiePresent] = useState("");

  const [authenticationResult, setAuthenticationResult] = useState<any>({
    validatePassword: {
      result: -1,
      isLoading: false,
      error: null,
    },
    generateOtp: {
      res: null,
      isLoading: false,
      error: null,
    },
    validateOtp: {
      needOtpVal: false,
      result: -1,
      isLoading: false,
      error: null,
    },
  });
  const [passwordRes, setPasswordRes] = useState<{
    result: number;
    isLoading: boolean;
    isFailed: boolean;
    token: string;
  }>({
    isLoading: false,
    result: -1,
    isFailed: false,
    token: "",
  });
  const [otpRes, setOtpRes] = useState<{ result: number; isLoading: boolean }>({
    isLoading: false,
    result: -1,
  });
  const [authSuccess, setAuthSuccess] = useState<{
    result: boolean;
    token: string;
    canRoute: boolean;
  }>({
    result: false,
    token: "",
    canRoute: false,
  });
  
  const [isOtpDisable, setIsOtpDisable] = useState<boolean>(true);

  const [isPwdValidated, setPwdValidated] = useState(false);
  const [isOtpEntered, setOtpEntered] = useState(false);
  const [isOnlySuccessAuthSucc, setOnlySuccessAuthSucc] = useState(false);
  const [showPwdText, setShowPwdText] = useState(false);
  //   const getAuthResult = (result: number) => {
  //     switch (result) {
  //       case 0:
  //         return AuthResults.INVALID;
  //       case 1:
  //         return AuthResults.DONE;
  //       case 2:
  //         return AuthResults.OTP_AUTH_NEED;
  //       case 3:
  //         return AuthResults.SSO_AUTH_NEED;
  //       default:
  //         return AuthResults.INVALID;
  //     }
  //   };

  useEffect(() => {
    if (loginData.otp?.length >= 4) {
      setOtpEntered(true);
    } else {
      setOtpEntered(false);
    }
  }, [loginData.otp, isPwdValidated]);

  const onLoginSuccess = (email: any, token: string) => {
    sessionStorage.setItem(CLocalStorage.Keys.Email, email);
    sessionStorage.setItem(CLocalStorage.Keys.Token, token); 

    //localStorage.setItem(CLocalStorage.Keys.Email, email);
    //localStorage.setItem(CLocalStorage.Keys.Token, token);

    console.log(sessionStorage.getItem(CLocalStorage.Keys.Email));
    history.replace(CRoutePaths.Applications);
  };


  
  const onUserNameBlur = (lLoginData : ILoginData | undefined ) => {
    
    var llogData = lLoginData != undefined ? lLoginData : loginData
    
    if (llogData.userName.trim().length === 0) {
      setHelperText({
        ...helperText,
        userName: { error: true, text: "Enter the UserName" },
      });
    } else {
      setHelperText({ ...helperText, userName: { error: false, text: "" } });
    }
    //checkPasswordValidation(llogData.userName,llogData.password);
  };  

  const onPasswordBlur = (lLoginData : ILoginData | undefined ) => {
    var llogData = lLoginData != undefined ? lLoginData : loginData
    if (llogData.password.trim().length === 0) {
      setHelperText({
        ...helperText,
        password: { error: true, text: "Enter the password" },
      });
    } else {
      setHelperText({ ...helperText, password: { error: false, text: "" } });
    }
    //checkPasswordValidation(llogData.userName,llogData.password);
  };  

  const checkPasswordValidation = async (userName? : string,password? : string) => {
    
    var userN : string = "";
    var pwd : string = "";

    if(userName != undefined && password != undefined){     
      userN = userName;
      pwd = password;
    }
    else{
      userN = loginData.userName;
      pwd = loginData.password
    }

    if (
      userN?.trim().length > 0 &&
      pwd?.trim().length > 0
    ) {
      await validatePassword(userN,pwd);
    }
  };

  const checkPwdBounce = useDebounce(checkPasswordValidation,500);

  const onChangeLoginDataHandler = (e: any) => {
    
    var data = { ...loginData, [e.target.name]: e.target.value?.trim() }
    setLoginData(data);

    if (e.target.name == "userName" || e.target.name == "password") {
      setPasswordRes({
        result: -1,
        isLoading: true,
        isFailed: false,
        token: "",
      });
      setOtpEntered(false);
    }

    onUserNameBlur(data);
    onPasswordBlur(data);

    if(e.target.name == "userName" || e.target.name == "password"){
      checkPwdBounce(data.userName,data.password);
    }
    onOtpBlur(data)
  };

  var numReg = /^\d+$/;

  const onOtpBlur = (lLoginData : ILoginData | undefined ) => {
    var llogData = lLoginData != undefined ? lLoginData : loginData
    if (llogData.otp.trim().length === 0) {
      setHelperText({
        ...helperText,
        otp: { error: true, text: "Enter the otp" },
      });
    }
    else if(!numReg.test(llogData.otp.trim())) {
      setHelperText({
        ...helperText,
        otp: { error: true, text: "Enter the valid otp" },
      });
    }
    else {
      setHelperText({ ...helperText, otp: { error: false, text: "" } });
    }
  };

  useEffect(() => {
    var isValid = false;
    var isAuthScc = false;

    if (passwordRes.result == AuthResults.DONE) {
      isValid = true;
      setAuthSuccess({
        result: true,
        token: passwordRes.token,
        canRoute: false,
      });
      isAuthScc = true;
      // setHelperText({
      //   ...helperText,
      //   password: { error: false, text: "Validated success" },
      // });
    } else if (passwordRes.result == AuthResults.OTP_AUTH_NEED) {
      isValid = true;
      setHelperText({
        ...helperText,
        otp: { error: false, text: "Please enter the otp" },
      });
    } else if (passwordRes.result == AuthResults.INVALID) {
      setHelperText({
        ...helperText,
        password: { error: true, text: "Incorrect email address or password" },
      });
    } else if (passwordRes.result == AuthResults.PASSWORD_RESET_NEED) {
      isValid = true;
      isAuthScc = true;
    }
    else if(passwordRes.result == AuthResults.SERVER_ERROR){
      setHelperText({
        ...helperText,
        password: { error: true, text: "Unable to connect to server or No internet" },
      });
    } 
    else {
      setHelperText({
        ...helperText,
        password: { error: true, text: "" },
      });
    }

    setOnlySuccessAuthSucc(isAuthScc);
    setPwdValidated(isValid);
  }, [passwordRes.result]);

  const validatePassword = async (userName: string ,password: string) => {
    setPasswordRes({ result: -1, isLoading: true, isFailed: false, token: "" });

    var passRes = { result: -1, isLoading: false, isFailed: false, token: "" };
    try{
      var res = await API_SSO.ValidationPassword(
        userName,
        password
      );
      console.log(res);
  
      
      if (res.data?.result != undefined) {
        passRes.result = res.data.result;
        passRes.token = res.data.token;
      }
  
    }
    catch(err){
      console.error(err)      
      passRes.result = AuthResults.SERVER_ERROR;
    }
    
    setPasswordRes(passRes);
  };

  const generateOtp = async () => {
    var res = await API_SSO.GenerateOtp(loginData.userName);
    console.log(res);
    if (res.data) {
      setHelperText({
        ...helperText,
        otp: { error: false, text: "OTP sent to your registered e-mail ID" },
      });
    }
    // setAuthenticationResult({
    //   ...authenticationResult,
    //   generateOtp: { res: res, isLoading: isLoading, error: error },
    // });
  };

  useEffect(() => {
    if (authSuccess.result && authSuccess.canRoute) {
      SetSSOToken(authSuccess.token);
      onLoginSuccess(loginData.userName, authSuccess.token);
    }
  }, [authSuccess]);

  const validateOtp = async () => {
    var res = await API_SSO.ValidationOtp(loginData.userName, loginData.otp);
    console.log("res", res);
    if (res?.data?.result != undefined) {
      if (res.data.result == AuthResults.DONE) {
        setAuthSuccess({
          result: true,
          token: res.data.token,
          canRoute: true,
        });
      } else if (res.data.result == AuthResults.PASSWORD_RESET_NEED) {
        props.onEnableChangePwd(loginData.userName);
      } else if (res.data.result == AuthResults.INVALID) {
        setHelperText({
          ...helperText,
          otp: { error: true, text: "Incorrect OTP" },
        });
      }
    }
    // setAuthenticationResult({
    //   ...authenticationResult,
    //   validateOtp: { res: res, isLoading: isLoading, error: error },
    // });
  };

  useEffect(() => {
    console.log("authenticationResult", authenticationResult);
    var rememberData: any = getRememberMeDetails(CLocalStorage.Keys.RememberMe);
        console.log(rememberData)
        SetRememberMe(rememberData.isChecked)
        if (rememberData.isChecked === true) {
            setLoginData({
                userName: rememberData.UserName,
                password: rememberData.Password,
                otp: ""
            })
         
            checkPasswordValidation(rememberData.UserName,rememberData.Password);
        }
  }, [authenticationResult]);

  useEffect(() => {
    var manager = new CookieManager();
    const cookiesPresent = manager.getCookie(false);
    setIsCookiePresent(cookiesPresent);
    if (cookiesPresent) {
      // window.history.replaceState(null, '', window.location.href);

      // window.addEventListener('popstate', () => {
      //   window.history.forward();
      // });

      console.log(cookiesPresent);

      var email = sessionStorage.getItem(CLocalStorage.Keys.Email);
      history.replace(CRoutePaths.Applications);
    } else {
      console.log("Error ");
    }

    // return () => {
    //   window.removeEventListener('popstate', () => {
    //     window.history.forward();
    //   });
    // };
    return;
  }, [history]);

  const handleOtpSubmit = async (e: any) => {
    e.preventDefault();

    performRememberMe(CLocalStorage.Keys.RememberMe,{isEnabled : isRememberme,userName: loginData.userName,password : loginData.password});

    if (isOnlySuccessAuthSucc) {
      if (passwordRes.result == AuthResults.PASSWORD_RESET_NEED) {
        props.onEnableChangePwd(loginData.userName);
      } else {
        setAuthSuccess({
          result: authSuccess.result,
          token: authSuccess.token,
          canRoute: true,
        });
      }
    }
    else if (isOtpEntered) {
      if (loginData.otp.trim().length > 0) {
        await validateOtp();
      }
    } 
  };

  const handleResetPwd = async () => {
    setLoginData({ userName: loginData.userName, otp: "", password: "" });
    var res = await API_SSO.ResetPassword(loginData.userName);
    if (res.data.status.includes("SUC:200")) {
      setModalOpen(true);

      setTimeout(()=>{
        setModalOpen(false);
      }, 3000)
      // setHelperText({
      //   ...helperText,
      //   userName: {
      //     error: false,
      //     text: "Password has been reset successfully",
      //   },
      // });
    } else if (res.data.status.includes("ERR")) {
      var error = "Unable to reset the password";
      res.data.errors.map((err: any) => {
        if (err.message == "emailID") {
          error = "User not found";
        }
      });
      setHelperText({
        ...helperText,
        userName: { error: true, text: error },
      });
    }
  };

  return (
    <>
      <Modal
        title={"Information"}
        open={isModalOpen}
        closeIcon={false}
        onOk={() => {
          setModalOpen(false);
        }}
        // style={style}
        onCancel={() => {
          setModalOpen(false);
        }}
        footer={[]}
        width={600}
        maskClosable={false}
        //afterClose={afterClose}
        //keyboard={keyboard}
        //closable={closable}

        centered={true}
      >
        <label>Temporary password sent to your registered e-mail ID</label>
      </Modal>

      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack direction={"row"} justifyContent={"center"} sx={inputDivStyle}>
          <img src={discoverImg} />
        </Stack>
        <div style={inputDivStyle}>
          <GInput
            type="email"
            name="userName"
            value={loginData.userName}
            error={helperText.userName.error}
            helperText={helperText.userName.text}
            onChange={onChangeLoginDataHandler}
            // onBlur={onUserNameBlur}
            label="Email Address"
          />
        </div>
        <div style={inputDivStyle}>
          <GInput            
            type={showPwdText ? "text" : "password"}
            name="password"
            value={loginData.password}
            error={helperText.password.error}
            helperText={helperText.password.text}
            onChange={onChangeLoginDataHandler}
            //onBlur={onPasswordBlur}
            label="Password"
            icons={{
              end: (
                <span
                  onClick={() => {
                    setShowPwdText(!showPwdText);
                  }}
                  style={{
                    color: "#EC6C2A",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                >
                  {showPwdText ? PHIcons.EyeOff : PHIcons.EyeOn}
                </span>
              ),
            }}
          />
        </div>

        {passwordRes.result == AuthResults.OTP_AUTH_NEED && <div
          style={inputDivStyle}
        >
          <GInput
            type="text"
            name="otp"
            value={loginData.otp}
            error={helperText.otp.error}
            helperText={helperText.otp.text}
            onChange={onChangeLoginDataHandler}
            //onBlur={onOtpBlur}
            label="Enter Current OTP"
            icons={{
              end: (
                <span
                  onClick={generateOtp}
                  style={forgotspanStyle}
                  title="Generate"
                >
                  {/* {PHIcons.CachedIcon} */}
                  Generate
                </span>
              ),
            }}
          />
        </div>}

        <div
          style={
            loginData.userName?.length > 0 ? inputDivStyle : disabledDivStyle
          }
        >
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}            
          >
            <span style={forgotspanStyle} onClick={handleResetPwd}>Forgot Password</span>
          </Stack>
        </div>

        <Stack style={rememberMeStyle} direction={"row"} justifyContent={"flex-start"} spacing={1} >
            <input style={checkBoxStyle}
                type="checkbox"
                name="isEnabled"
                checked={isRememberme}
                onChange={(e) => SetRememberMe(e.target.checked)}
            />
            <label style={{fontSize: "16px"}}>Remember me</label>
        </Stack>

        <button
          onClick={handleOtpSubmit}
          disabled={!isOtpEntered && !isOnlySuccessAuthSucc && !isPwdValidated}
          style={
            isPwdValidated && (isOtpEntered || isOnlySuccessAuthSucc)
              ? submitButtonStyle
              : submitDisableButtonStyle
          }
        >
          Submit
        </button>
      </Stack>
    </>
  );
};

export default LoginForm;
